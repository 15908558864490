// Sidebar.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

axios.defaults.baseURL = 'https://byyb.live';

const Sidebar = ({ onDjSelect, onProgramSelect }) => {
  const [djs, setDjs] = useState([]);

  const programs = [
    { code: 'rs', name: 'byyb.radio' },
    { code: 'ia', name: 'ivy arum session' },
    { code: 'hr', name: 'heimliday radio' },
    { code: 'bo', name: 'byyb.out' },
  ];

  // Load all DJs on component mount
  useEffect(() => {
    axios
      .get('/api/djs')
      .then((response) => {
        const sortedDjs = response.data.sort((a, b) => a.name.localeCompare(b.name));
        setDjs(sortedDjs);
      })
      .catch((error) => console.error('Error fetching DJs:', error));
  }, []);

  return (
    <div className="sidebar">
      <ul>
        <li 
          onClick={() => onProgramSelect(null)} 
          className="sidebar-heading"
        >
          All Programs
        </li>
        {programs.map((program) => (
          <li
            key={program.code}
            onClick={() => onProgramSelect(program.code)}
            className="sidebar-item"
          >
            {program.name}
          </li>
        ))}
      </ul>
      <ul>
        <li 
          onClick={() => onDjSelect(null)} 
          className="sidebar-heading"
        >
          All DJ/Artists
        </li>
        {djs.map((dj) => (
          <li
            key={dj.id}
            onClick={() => onDjSelect(dj)}
          >
            {dj.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
