import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import DjSets from './DjSets';
import MusicPlayer from './MusicPlayer';
import TopMenu from './TopMenu';
import './App.css';

const App = () => {
  const [selectedDj, setSelectedDj] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true); // Set default to true for initial render
  const [currentTrack, setCurrentTrack] = useState('');
  const [currentSet, setCurrentSet] = useState(null); // Store the current set object
  const [djInfo, setDjInfo] = useState(null); // Store the DJ information


  useEffect(() => {
    const handleResize = () => {
      setSidebarOpen(window.innerWidth > 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSetSelection = (set) => {
    setCurrentTrack(set.audio_file_url);
    setCurrentSet(set);
    setDjInfo(set.dj);
  };

  const handleDjSelect = (dj) => {
    setSelectedDj(dj);
    setSelectedProgram(null); // Reset program selection
  };

  const handleProgramSelect = (programCode) => {
    setSelectedProgram(programCode);
    setSelectedDj(null); // Reset DJ selection
  };


  return (
    <div className="app">
      <TopMenu toggleSidebar={toggleSidebar} />


      <div className={`sidebar-container ${sidebarOpen ? 'show' : ''}`}>
        <Sidebar onDjSelect={handleDjSelect} onProgramSelect={handleProgramSelect} />
      </div>

      <div className="main-content">
        <DjSets 
          selectedDj={selectedDj} 
          selectedProgram={selectedProgram} 
          onSetSelect={handleSetSelection} 
        />
      </div>
      <MusicPlayer 
        audioSrc={currentTrack} 
        selectedSet={currentSet} 
        djInfo={djInfo} 
      />
    </div>
  );
};

export default App;
