import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

axios.defaults.baseURL = 'https://byyb.live';
// axios.defaults.baseURL = 'http://localhost:8000';
const DjSets = ({ selectedDj, selectedProgram, onSetSelect }) => {
  const [djSets, setDjSets] = useState([]);
  const [djInfo, setDjInfo] = useState(null);
  const [programInfo, setProgramInfo] = useState(null);

  // Infinite scroll state
  const [page, setPage] = useState(1);  // Track the current page
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Whether more sets are available
  const observer = useRef();  // To observe the last item

  // Fetch DJ sets with infinite scroll support
  const loadMoreSets = () => {
    setLoading(true);
    let url = `/api/performances/?page=${page}&limit=12`; // Load 12 sets per request
    let params = [];

    if (selectedDj) {
      params.push(`dj_id=${selectedDj.id}`);
    } else if (selectedProgram) {
      params.push(`program=${selectedProgram}`);
    }

    if (params.length > 0) {
      url += '&' + params.join('&');
    }

    axios
      .get(url)
      .then((response) => {
        let sets = response.data.sets || response.data;
        let djInfo = response.data.djInfo || null;
        let programInfo = response.data.programInfo || '';

        sets = sets.filter((set) => set.publish_date && set.audio_file);

        // Append the new sets to the current list
        setDjSets((prevSets) => [...prevSets, ...sets]);
        setDjInfo(djInfo);
        setProgramInfo(programInfo);

        // If fewer sets are returned, no more sets to load
        setHasMore(sets.length > 0);
        setPage((prevPage) => prevPage + 1);  // Increment the page
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching DJ sets:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    setDjSets([]); // Clear the sets when DJ or program is changed
    setPage(1);  // Reset page
    setHasMore(true);  // Reset hasMore
    loadMoreSets();  // Load initial sets
  }, [selectedDj, selectedProgram]);

  // Infinite scroll: Observe the last set
  const lastSetRef = (node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMoreSets();  // Load more sets when the last set is visible
      }
    });

    if (node) observer.current.observe(node);  // Attach the observer to the last set
  };

  // Individual DJ set item with lazy loading for thumbnails and video
  const SetItem = ({ set }) => {
    const videoRef = useRef(null);
    const imgRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && imgRef.current) {
            imgRef.current.src = set.square_thumbnail_url;  // Load the thumbnail when in view
            observer.disconnect();
          }
        });
      });
      if (imgRef.current) observer.observe(imgRef.current);
    }, [set.square_thumbnail_url]);

    return (
      <div
        className="set-item"
        onClick={() => onSetSelect(set)}
        onMouseEnter={() => {
          if (videoRef.current) {
            videoRef.current.play();
            videoRef.current.style.opacity = 1;
          }
        }}
        onMouseLeave={() => {
          if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            videoRef.current.style.opacity = 0;
          }
        }}
      >
        <img
          ref={imgRef}
          data-src={set.square_thumbnail_url}
          alt={set.identifier}
          width="200"
          height="200"
          className="set-thumbnail"
        />
        {set.snippet_file_url && (
          <video
            ref={videoRef}
            src={set.snippet_file_url}
            width="200"
            height="200"
            className="set-snippet"
            muted
            preload="metadata"
            style={{ opacity: 0 }}
          />
        )}
        {/* <p>{set.identifier}</p> */}
      </div>
    );
  };

  return (
    <div className="dj-sets-container">
      {djInfo && selectedDj && (
        <div className="dj-info">
          <h1 className="dj-name">{djInfo.name}</h1>
          <p className="dj-bio">{djInfo.bio}</p>
        </div>
      )}

      {programInfo && (
        <div className="dj-info">
          <h1 className="dj-name">{programInfo.name}</h1>
          <p className="dj-bio">{programInfo.intro}</p>
        </div>
      )}

      <div className="dj-sets">
        {djSets.map((set, index) => {
          if (djSets.length === index + 1) {
            // Attach the ref to the last set for infinite scrolling
            return (
              <div
                ref={lastSetRef}
                key={set.id}
                className="set-item"
                onClick={() => onSetSelect(set)}
              >
                <SetItem set={set} />
              </div>
            );
          } else {
            return (
              <div key={set.id} className="set-item" onClick={() => onSetSelect(set)}>
                <SetItem set={set} />
              </div>
            );
          }
        })}
      </div>

      {loading && <p>Loading more sets...</p>}
    </div>
  );
};

export default DjSets;
