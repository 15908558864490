// TopMenu.js
import React from 'react';

const TopMenu = ({ toggleSidebar }) => {
  const tickerText = "This is some long text that will scroll across the screen. Welcome to our website! Enjoy your stay.";

  return (
    <div className="top-menu">
      <div className="logo">
        <img src="/static/logo/byyb.png" alt="Logo" />
      </div>
      <div className="ticker">
        <div className="ticker-text">
          Program this week: wk.34 at the radio booth. 2pm LXY, 5pm Siheng, 6pm HauxHaux, 7pm Hitam, 8pm Agu, 9pm WVRL POOL. Program this week: wk.34 at the radio booth. 2pm LXY, 5pm Siheng, 6pm HauxHaux, 7pm Hitam, 8pm Agu, 9pm WVRL POOL.
          
        </div>
      </div>
      <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
        ☰
      </button>
    </div>
  );
};

export default TopMenu;
