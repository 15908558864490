import React, { useState, useRef, useEffect } from 'react';

const MusicPlayer = ({ audioSrc, selectedSet, djInfo }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [progress, setProgress] = useState(0);
  const [cuePoint, setCuePoint] = useState(0);
  const audioRef = useRef(null);

  const prevAudioSrc = useRef(audioSrc);

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const formattedH = h > 0 ? `${h.toString().padStart(2, '0')}:` : '';
    const formattedM = m.toString().padStart(2, '0');
    const formattedS = s.toString().padStart(2, '0');

    return `${formattedH}${formattedM}:${formattedS}`;
  };

  useEffect(() => {
    const audio = audioRef.current;

  
    // If a new audioSrc is selected, reset the cue point
    if (audioSrc !== prevAudioSrc.current) {
      setCuePoint(0); // Reset cue point when a new track is selected
      prevAudioSrc.current = audioSrc; // Update the reference to the new track
    }


    const updateProgress = () => {
      const percentage = (audio.currentTime / audio.duration) * 100;
      setProgress(percentage);
    };

    audio.addEventListener('timeupdate', updateProgress);

    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
    };
  }, [audioSrc]);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleCueClick = () => {
    const audio = audioRef.current;
    if (!isPlaying) {
      // When track is not playing, set cue point to current time
      setCuePoint(audio.currentTime);
    } else {
      // When track is playing, jump to cue point and continue playing
      audio.currentTime = cuePoint;
      setProgress((cuePoint / audio.duration) * 100);
    }
  };

  const handleProgressChange = (e) => {
    const manualChange = Number(e.target.value);
    const audio = audioRef.current;
    audio.currentTime = (audio.duration / 100) * manualChange;
    setProgress(manualChange);
  };

  return (
    <div className="music-player">
      {selectedSet && (
        <div className="set-info">
          {djInfo && djInfo.length > 0 ? (
            <ul className="set-info-main">
              {djInfo.map((dj) => (
                <li key={dj.id}>{dj.name}</li>
              ))}
            </ul>
          ) : (
            <p>No DJ</p>
          )}
          <ul>
            <li className="set-info-secondary">{selectedSet.date}</li>
            <li className="set-info-secondary">{selectedSet.program}</li>
          </ul>
        </div>
      )}

      <audio ref={audioRef} src={audioSrc} autoPlay={isPlaying} />

      {/* CUE Button */}
      <button
        className="cue-btn"
        onClick={handleCueClick}
        aria-label="CUE"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="black"
          xmlns="http://www.w3.org/2000/svg"
        >
          <text
            x="12"
            y="16"
            textAnchor="middle"
            fontSize="12"
            fill="black"
            fontFamily="Arial, sans-serif"
          >
            CUE
          </text>
        </svg>
      </button>

      {/* Play/Pause Button */}
      <button className="play-pause-btn" onClick={handlePlayPause}>
        {isPlaying ? (
          // Pause Icon (Two vertical bars)
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="6" y="5" width="4" height="14" />
            <rect x="14" y="5" width="4" height="14" />
          </svg>
        ) : (
          // Play Icon (Triangle)
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon points="6,4 20,12 6,20" />
          </svg>
        )}
      </button>

      <div className="progress-container">
        <input
          type="range"
          value={progress}
          onChange={handleProgressChange}
          style={{ width: '100%' }}
        />
        {/* Cue Point Indicator */}
        {audioRef.current && audioRef.current.duration && cuePoint > 0 && (
          <div
            className="cue-point-marker"
            style={{
              left: `${(cuePoint / audioRef.current.duration) * 100}%`,
            }}
          ></div>
        )}
      </div>



      <span className="music-player-timer">
        {formatTime(audioRef.current?.currentTime || 0)} / {formatTime(audioRef.current?.duration || 0)}
      </span>
    </div>
  );
};

export default MusicPlayer;
